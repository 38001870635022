export default {
    name: 'Depmic',
    data: () => ({
        modmade: false,
        createdm: false,
        pickdm: false,
        newd: '',
        newm: '',
        readyfornew: true,
        duplicatevals: '',
        prefilllist: [],
        duplicateselect: true,
        exportstores: false,
        readyforexport: true,
        dmchoice: [],
        recsdisplayed: 0,
        storecols: [
            { text: 'Assigned', value: 'value', sortable: true },
            { text: 'Store Number', value: 'store', sortable: true },
            { text: 'Store Name', value: 'storename', sortable: true },
            { text: 'Selected', value: 'isassign', sortable: true },
            //{ text: '', value: 'value' }
        ],
    }),
    async mounted() {
        this.$store.dispatch('Contact/fetchDmList', null, { root: true })
        this.$store.dispatch('Contact/fetchMissing', null, { root: true })

        this.dmstatus.savemsg = false
        this.dmstatus.savemsgerr = false
    },
    computed: {
        user: {
            get() {
                return this.$store.getters['Sgs/getUser']
            },
        },
        depmic: {
            get() {
                return this.$store.getters['Contact/getDepMic']
            },
            set(val) {
                this.$store.commit('Contact/SET_DEPMIC', val)
            },
        },
        dmstatus: {
            get() {
                return this.$store.getters['Contact/getDmStatus']
            },
        },
        depmiclist: {
            get() {
                return this.$store.getters['Contact/getDmList']
            },
            set(val) {
                this.$store.commit('Contact/SET_DMLIST', val)
            },
        },
        depmictotaldms: {
            get() {
                return this.$store.getters['Contact/getDmTotalAss']
            },
        },
        depmicmissinglist: {
            get() {
                return this.$store.getters['Contact/getDmMissingList']
            },
        },
        depmicmissingfields: {
            get() {
                return this.$store.getters['Contact/getDmMissingFields']
            },
        },
        depmicmissingstores: {
            get() {
                return this.$store.getters['Contact/getDmMissingStores']
            },
        },
    },
    methods: {
        getDM() {
            this.$store.dispatch('Contact/fetchSingle', null, { root: true }).then(() => {
                this.$store.dispatch('Contact/fetchMissing', null, { root: true })
            })
        },
        refreshMissing() {
            this.$store.dispatch('Contact/fetchMissing', null, { root: true })
        },
        showEdit() {
            this.createdm = true
            this.$nextTick(() => this.$refs.dcode.focus())
        },
        goSave() {
            this.$store.dispatch('Contact/saveContact', { whichone: 'company' }, { root: true })
            this.modmade = false
        },
        showExport() {
            this.exportstores = true
        },
        putFile() {
            this.uploadthis = this.$refs.inputUpload.files[0]

            if (!this.uploadthis) {
                console.log('NOT THERE')
            } else {
                let formdata = new FormData()
                formdata.append('file', this.uploadthis)

                this.$store.commit('Contact/SET_UPLOADINGFILE', true)
                this.$store.dispatch('Contact/importStores', formdata, { root: true })
            }
        },
        changeGroup(changeop) {
            this.depmic.templates = []

            this.$store.dispatch('Contact/modStoreBulk', changeop, { root: true })
        },
        checkDepts(e) {
            let workd = e.replace(/^0*/g, ''),
                foundone = false
            this.prefilllist = []

            // console.log('CHECKING')
            // console.log(workd)

            for (let idx = 0; idx < this.depmiclist.length; idx++) {
                if (this.depmiclist[idx].value.split('-')[0] === workd) {
                    foundone = true
                    // console.log(this.depmiclist[idx])
                    this.prefilllist.push(this.depmiclist[idx])
                }
            }

            if (foundone) {
                this.pickdm = true
                alert(
                    'The system has identified an existing department that may contain values you can use.\nThe next form will provide choices from which to choose.'
                )
            }
        },
        enableNew() {
            if (!this.newm) {
                this.readyfornew = true
            } else {
                this.readyfornew = false
            }
        },
        clearDm() {
            this.depmic.selected = ''
            this.newd = ''
            this.newm = ''
            this.createdm = false
            this.$store.commit('Contact/RESET_DEPMIC')
        },
        makeDm() {
            if (
                !this.newd ||
                !this.newm ||
                !this.newd.length ||
                !this.newm.length ||
                this.newd === '' ||
                this.newm === ''
            ) {
                alert('The system requires both a departmnet and mic code to proceed.')
            } else {
                /* --- get rid of leading zeros --- */
                let workd = this.newd.replace(/^0*/g, ''),
                    workm = this.newm.replace(/^0*/g, ''),
                    checkval = workd + '-' + workm

                if (this.depmiclist.find(o => o.value === checkval)) {
                    alert('The system cannot proceed, this D/M combination already exists.')
                } else {
                    if (this.duplicatevals !== '') {
                        let dupevals = this.duplicatevals.split('-')

                        this.$store
                            .dispatch(
                                'Contact/makeDm',
                                {
                                    op: 'dupedm',
                                    dep: workd,
                                    mic: workm,
                                    dupedep: dupevals[0],
                                    dupemic: dupevals[1],
                                    user: this.user,
                                },
                                { root: true }
                            )
                            .then(() => {
                                this.createdm = false
                            })
                            .catch(() => {
                                this.createdm = false
                            })
                    } else {
                        this.$store
                            .dispatch(
                                'Contact/makeDm',
                                { op: 'makedm', dep: workd, mic: workm, buyer: this.user.email, user: this.user },
                                { root: true }
                            )
                            .then(() => {
                                this.createdm = false
                            })
                            .catch(() => {
                                this.createdm = false
                            })
                    }
                }
            }
        },
        getMissing() {
            //this.deptmic.selected = this.deptmic.missingstoresel
            //this.goget(e)
        },
        duplicatePicked() {
            this.duplicateselect = false
        },
        clearPick() {
            this.duplicatevals = ''
            this.pickdm = false
        },
        useExisting() {
            this.duplicatevals = this.depmic.selected
            this.pickdm = false
        },
        pickItDm() {
            if (this.dmchoice.length) {
                this.readyforexport = false
            }
        },
        clearExport() {
            this.exportstores = false
        },
        externalStores() {
            this.$store.dispatch('Sgs/sendSheet', { op: 'dmstores', type: this.dmchoice })
            this.exportstores = false
        },
        filterIt(searchthis) {
            this.dmstatus.search = searchthis
        },
        getTotalItems(e) {
            this.recsdisplayed = e.itemsLength
        },
        changeSel(item) {
            this.$store.dispatch('Contact/modStoreAssign', item)
        },
    },
}
