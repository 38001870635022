<template>
    <v-container fluid>
        <v-row v-if="$route.path.indexOf('/admin/depmic') !== -1" class="text-center">
            <keep-alive>
                <DMEdit :key="$route.fullPath" />
            </keep-alive>
        </v-row>
        <v-row v-if="$route.path.indexOf('/admin/contact') !== -1" class="text-center">
            <keep-alive>
                <ContactEdit :key="$route.fullPath" />
            </keep-alive>
        </v-row>
    </v-container>
</template>

<script>
import DMEdit from '@/components/DMForm.vue'
import ContactEdit from '@/components/ContactForm.vue'

export default {
    name: 'Contact',
    components: {
        DMEdit,
        ContactEdit,
    },
}
</script>