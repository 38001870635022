<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" v-show="!depmic[ctlfield]" color="red" @click="grabudvals(ctlfield)">
                mdi-arrow-bottom-left-thin-circle-outline
            </v-icon>
        </template>
        <span>Click to query for existing values.</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'contactbutton',
    props: ['ctlfield'],
    computed: {
        depmic: {
            get() {
                return this.$store.getters['Contact/getDepMic']
            },
            set(val) {
                this.$store.commit('Contact/SET_DEPMIC', val)
            },
        },
        dm: {
            get() {
                return this.$store.getters['Contact/getDm']
            },
        },
    },
    methods: {
        grabudvals(field) {
            this.dm.selectedupdate = ''
            this.$store.dispatch('Contact/fetchUpdateVals', { field: field }, { root: true })
        },
    },
}
</script>